<template>
  <div class="container-fluid">
    <div v-if="user">
      <!--Driver ID-->
      <b-row>
        <b-col>
          <b-card
            style="width: 300px; position: absolute; right: 15px; top: -30px"
          >
            DRIVER ID
            <b-card
              class="bg-danger position-absolute text-white"
              style="right: 0; top: 0"
            >
              <b>{{ user.id }}</b>
            </b-card>
          </b-card>
        </b-col>
      </b-row>
      <!--Driver Details-->
      <b-card class="profile-card my-5 ml-5">
        <b-avatar
          class="offset-left"
          :src="$s3URL + 'uploads/user/' + user.pic"
          size="12rem"
          variant="primary"
        />
        <b-row
          align-v="center"
          class="avatar-offset"
        >
          <b-col class="">
            <div>
              <h4 class="text-uppercase">
                {{ user.name }}
              </h4>
              <p>{{ user.phone }}</p>
            </div>
          </b-col>
          <b-col
            v-if="verified"
            class="text-right"
          >
            <div>
              <img
                :src="require('@/assets/images/icons/verified.png')"
                style="width: 40px"
              >
            </div>
          </b-col>
        </b-row>
      </b-card>
      <!--Driver Vehicle-->
      <b-card class="profile-card ml-5">
        <feather-icon
          icon="TruckIcon"
          size="40"
          class="text-primary position-absolute"
        />
        <b-row
          align-v="center"
          class="ficon-offset"
        >
          <b-col
            align-v="center"
            class="details border-left"
          >
            <h4>MODEL OF VEHICLE</h4>
            <p>
              {{
                user.vehicles
                  .map((a) => a.model)
                  .filter((value, index, self) => self.indexOf(value) === index)
                  .join()
              }}
            </p>
          </b-col>
          <b-col
            align-v="center"
            class="details"
          >
            <h4>MAKE OF VEHICLE</h4>
            <p>
              {{
                user.vehicles
                  .map((a) => a.make)
                  .filter((value, index, self) => self.indexOf(value) === index)
                  .join()
              }}
            </p>
          </b-col>
          <b-col
            align-v="center"
            class="details"
          >
            <h4>TOTAL NUMBER OF VEHICLES</h4>
            <p>{{ user ? user.vehicles.length : '' }}</p>
          </b-col>
        </b-row>
      </b-card>
      <b-row>
        <!--Address-->
        <b-col>
          <b-card class="profile-card ml-5">
            <feather-icon
              icon="MapPinIcon"
              size="40"
              class="text-primary position-absolute"
            />
            <b-row
              align-v="center"
              class="ficon-offset"
            >
              <b-col
                align-v="center"
                class="details border-left"
              >
                <h4>ADDRESS</h4>
                <div
                  v-for="address in user.addresses"
                  :key="address.id"
                >
                  <p>
                    <b>{{ address.name }}</b><br>
                    <i>{{ address.phone }}</i><br>
                    {{ address.complete }}
                  </p>
                  <hr>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <!--Phone-->
        <b-col>
          <b-card class="profile-card">
            <feather-icon
              icon="PhoneIcon"
              size="40"
              class="text-primary position-absolute"
            />
            <b-row
              align-v="center"
              class="ficon-offset"
            >
              <b-col
                align-v="center"
                class="details border-left"
              >
                <h4>PHONE</h4>
                <p>{{ user.a_phone }}</p>
              </b-col>
            </b-row>
          </b-card>
          <!--Email-->
          <b-card class="profile-card">
            <feather-icon
              icon="UserIcon"
              size="40"
              class="text-primary position-absolute"
            />
            <b-row
              align-v="center"
              class="ficon-offset"
            >
              <b-col
                align-v="center"
                class="details border-left"
              >
                <h4>EMAIL ADDRESS</h4>
                <p>{{ user.email }}</p>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <!--Driver List-->
      <b-card class="profile-card ml-5">
        <feather-icon
          icon="UsersIcon"
          size="40"
          class="text-primary position-absolute"
        />
        <b-row
          align-v="center"
          class="ficon-offset"
        >
          <b-col
            align-v="center"
            class="details border-left"
          >
            <h4 style="float: left">
              DRIVERS
            </h4>
            <driver-list :rows="user.drivers" />
          </b-col>
        </b-row>
      </b-card>
      <!--Vehicle List-->
      <b-card class="profile-card ml-5">
        <feather-icon
          icon="TruckIcon"
          size="40"
          class="text-primary position-absolute"
        />
        <b-row
          align-v="center"
          class="ficon-offset"
        >
          <b-col
            align-v="center"
            class="details border-left"
          >
            <h4 style="float: left">
              VEHICLES
            </h4>
            <vehicle-list :rows="user.vehicles" />
          </b-col>
        </b-row>
      </b-card>
      <!--Bank info-->
      <b-card class="profile-card ml-5">
        <feather-icon
          icon="TrelloIcon"
          size="40"
          class="text-primary position-absolute"
        />
        <b-row
          align-v="center"
          class="ficon-offset"
        >
          <b-col
            align-v="center"
            class="details border-left"
          >
            <h4 style="float: left">
              BANK INFORMATION
            </h4>
          </b-col>
        </b-row>
        <b-col class="mt-1 mx-5">
          <h5><b>Bank Name</b></h5>
          <p>{{ user.bank_name }}</p>
        </b-col>
        <b-col class="mx-5">
          <h5><b>Bank Account Number</b></h5>
          <p>{{ user.bank_account }}</p>
        </b-col>
      </b-card>

      <b-row>
        <b-col
          class="text-center"
        ><b-button
          type="button"
          variant="primary"
          class="width-250"
          @click="$router.go(-1)"
        >
          Back
        </b-button></b-col>
      </b-row>
    </div>
    <b-alert
      v-if="user"
      variant="info"
    >
      <h4 class="alert-heading">
        Oppps!
      </h4>
      <div class="alert-body">
        <span>System can't find this driver.</span>
      </div>
    </b-alert>
  </div>
</template>

<script>
import { BAlert } from 'bootstrap-vue'
import axios from '@axios'
import DriverList from '../../widgets/DriverList.vue'
import VehicleList from '../../widgets/VehicleList.vue'

export default {
  components: {
    DriverList,
    VehicleList,
    BAlert,
  },
  data() {
    return {
      verified: false,
      user: null,
    }
  },
  async mounted() {
    const userData = await axios.get(
      `/smarttruck/${this.$route.params.id}`,
    )
    this.user = userData.data.data
  },
  methods: {},
}
</script>
<style scoped>
.offset-left {
  position: absolute;
  left: -25px;
  top: -25px;
}
.avatar-offset {
  margin-left: 9rem !important;
}
.ficon-offset {
  margin-left: 4rem !important;
}
</style>
