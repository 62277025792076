<template>
  <div>
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Search all field"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>
    <vue-good-table
      style-class="vgt-table"
      :columns="columns"
      :rows="rows"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'name'">
          {{ props.row.first_name }} {{ props.row.last_name }}
        </span>
        <span class="text-uppercase" v-else-if="props.column.field === 'plate'">
          {{ props.row.plate }}
        </span>
        <span v-else-if="props.column.field === 'image'">
          <b-button
            v-if="props.row[props.column.field]"
            variant="success"
            size="sm"
            @click="
              showModal(
                `${$s3URL}uploads/user/${props.row[props.column.field]}`
              )
            "
          >
            <feather-icon icon="CheckIcon" /> Uploaded
          </b-button>

          <b-button v-else variant="danger" size="sm">
            <feather-icon icon="AlertCircleIcon" /> No Image
          </b-button>
        </span>
      </template>
      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> Showing 1 to </span>
            <b-form-select
              v-model="pageLength"
              :options="['5', '10', '20']"
              class="mx-1"
              @input="
                (value) => props.perPageChanged({ currentPerPage: value })
              "
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <b-modal ref="modal-1" title="Vehicle" ok-only ok-title="Close" size="lg">
      <b-card-text>
        <div class="container" style="min-height: 600px" v-html="modal"></div>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import { VueGoodTable } from "vue-good-table";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    VueGoodTable,
  },
  props: {
    rows: [],
  },
  data() {
    return {
      modal: "",
      pageLength: 5,
      dir: false,
      columns: [
        {
          label: "Plate",
          field: "plate",
        },
        {
          label: "Model",
          field: "model",
        },
        {
          label: "Make",
          field: "make",
        },
        {
          label: "Image",
          field: "image",
        },
      ],

      searchTerm: "",
    };
  },
  methods: {
    showModal(link) {
      this.modal = `<img style="width:100%;" src= "${link}" />`;
      this.$refs["modal-1"].show();
    },
    onPickFile(field, id) {
      this.$refs[`file-${field}-${id}`].click();
    },
    onFilePicked(event, field, id) {
      const self = this;
      const { files } = event.target;
      self.file = files[0];
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = async (e) => {
        self.image = e.target.result;
        const formData = new FormData();
        formData.append("table", "vehicles");
        formData.append("field", field);
        formData.append("id", id);
        formData.append("pic", self.file);
        await this.$http.post("/uploadFile", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Upload Successful!",
            icon: "ThumbsUpIcon",
            variant: "success",
          },
        });
        location.reload();
      };
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
