var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"custom-search d-flex justify-content-end"},[_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-center"},[_c('label',{staticClass:"mr-1"},[_vm._v("Search")]),_c('b-form-input',{staticClass:"d-inline-block",attrs:{"placeholder":"Search all field","type":"text"},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1)])],1),_c('vue-good-table',{attrs:{"style-class":"vgt-table","columns":_vm.columns,"rows":_vm.rows,"search-options":{
      enabled: true,
      externalQuery: _vm.searchTerm,
    },"pagination-options":{
      enabled: true,
      perPage: _vm.pageLength,
    }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'name')?_c('span',[_vm._v(" "+_vm._s(props.row.first_name)+" "+_vm._s(props.row.last_name)+" ")]):(props.column.field === 'plate')?_c('span',{staticClass:"text-uppercase"},[_vm._v(" "+_vm._s(props.row.plate)+" ")]):(props.column.field === 'image')?_c('span',[(props.row[props.column.field])?_c('b-button',{attrs:{"variant":"success","size":"sm"},on:{"click":function($event){return _vm.showModal(
              (_vm.$s3URL + "uploads/user/" + (props.row[props.column.field]))
            )}}},[_c('feather-icon',{attrs:{"icon":"CheckIcon"}}),_vm._v(" Uploaded ")],1):_c('b-button',{attrs:{"variant":"danger","size":"sm"}},[_c('feather-icon',{attrs:{"icon":"AlertCircleIcon"}}),_vm._v(" No Image ")],1)],1):_vm._e()]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(" Showing 1 to ")]),_c('b-form-select',{staticClass:"mx-1",attrs:{"options":['5', '10', '20']},on:{"input":function (value) { return props.perPageChanged({ currentPerPage: value }); }},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(" of "+_vm._s(props.total)+" entries ")])],1),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":1,"total-rows":props.total,"per-page":_vm.pageLength,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (value) { return props.pageChanged({ currentPage: value }); }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true)})],1)])]}}])}),_c('b-modal',{ref:"modal-1",attrs:{"title":"Vehicle","ok-only":"","ok-title":"Close","size":"lg"}},[_c('b-card-text',[_c('div',{staticClass:"container",staticStyle:{"min-height":"600px"},domProps:{"innerHTML":_vm._s(_vm.modal)}})])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }