<template>
  <div>

    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Search all field"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <span v-if="props.column.field == 'name'">
          {{ props.row.first_name }} {{ props.row.last_name }}
        </span>
        <span v-else-if="props.column.field === 'license'">
          <b-button
            v-if="props.row[props.column.field]"
            variant="success"
            size="sm"
            @click="showModal(`${$s3URL}uploads/user/${props.row[props.column.field]}`)"
          >
            <feather-icon
              icon="CheckIcon"
            /> Uploaded
          </b-button>
          <div
            v-else
            class="upload-button-container"
          >
            <input
              :ref="`file-${props.column.field}-${props.row.id}`"
              type="file"
              style="display: none"
              accept="image/*"
              @change="onFilePicked($event,props.column.field,props.row.id)"
            >
            <b-button
              variant="danger"
              class="btn-sm"
              @click="onPickFile(props.column.field,props.row.id)"
            >
              Upload
            </b-button>
          </div>
        </span>
      </template>
      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['5','10','20']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <b-modal
      ref="modal-1"
      title="Driver"
      ok-only
      ok-title="Close"
      size="lg"
      class="text-center"
    >
      <b-card-text>
        <b-container
          class="text-center"
          style="height: 50vh"
        >
          <b-img
            :src="modal"
            class="h-100"
          />
        </b-container>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    VueGoodTable,
  },
  props: {
    rows: {
      required: true,
      type: [Array, Object],
      default: () => {},
    },
  },
  data() {
    return {
      currentIndex: 0,
      modal: '',
      file: null,
      image: '',
      pageLength: 5,
      dir: false,
      columns: [
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'License',
          field: 'license',
        },
      ],

      searchTerm: '',
    }
  },
  methods: {
    showModal(link) {
      this.modal = link
      this.$refs['modal-1'].show()
    },
    onPickFile(field, id) {
      this.$refs[`file-${field}-${id}`].click()
    },
    onFilePicked(event, field, id) {
      const self = this
      const { files } = event.target
      self.file = files[this.currentIndex]
      const reader = new FileReader()
      reader.readAsDataURL(files[this.currentIndex])
      reader.onload = async e => {
        self.image = e.target.result

        const formData = new FormData()
        formData.append('table', 'drivers')
        formData.append('field', field)
        formData.append('id', id)
        formData.append('pic', self.file)
        await this.$http.post('/uploadFile', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Upload Successful!',
            icon: 'ThumbsUpIcon',
            variant: 'success',
          },
        })
        this.$route.go(0)
      }
    },
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
